import { render, staticRenderFns } from "./MobileUserPopup.vue?vue&type=template&id=167cf7e2&scoped=true"
import script from "./MobileUserPopup.vue?vue&type=script&lang=js"
export * from "./MobileUserPopup.vue?vue&type=script&lang=js"
import style0 from "./MobileUserPopup.vue?vue&type=style&index=0&id=167cf7e2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167cf7e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsIconChevronRight: require('/app/components/icons/IconChevronRight.vue').default,IconsIconLogout: require('/app/components/icons/IconLogout.vue').default})
