import { render, staticRenderFns } from "./MyAccountPopup.vue?vue&type=template&id=8af37668&scoped=true"
import script from "./MyAccountPopup.vue?vue&type=script&lang=js"
export * from "./MyAccountPopup.vue?vue&type=script&lang=js"
import style0 from "./MyAccountPopup.vue?vue&type=style&index=0&id=8af37668&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8af37668",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsIconUser: require('/app/components/icons/IconUser.vue').default,IconsIcon: require('/app/components/icons/Icon.vue').default})
